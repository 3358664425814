import React, { useEffect, useRef } from 'react';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import house from './dcpHouse.png';
import police from '../../assets/police.png'
import crimeGeneralIcon from '../../assets/General_crime_icon.png'
import HouseTheft from '../../assets/housetheft_icon.png'
import MVTHEFT from '../../assets/mvtheft_icon.png'
import SNATCHING from '../../assets/snatching_icon.png'
import Burglary from '../../assets/burglary_icon.png'
import Robbery from '../../assets/robbery_icon.png'
import cctvIcon from '../../assets/cctv_icon.png'
import privateCctvIcon from '../../assets/private_camera_icon.png'
import ANPRcctvIcon from '../../assets/ANPR_camera.png'
import ECILcctvIcon from '../../assets/ECIL_camera.png'
import alipur from './json_file_of_PS/Alipur.json'
import sawroopNagar from './json_file_of_PS/SawroopNagar.json'
import bhalswaDairy from './json_file_of_PS/BhalswaDairy.json'
import bawana from './json_file_of_PS/Bawana.json'
import nia from './json_file_of_PS/nia.json'
import samaypurBaldi from './json_file_of_PS/SamaypurBadli.json'
import shahabadDairy from './json_file_of_PS/ShahabadDairy.json'
import narela from './json_file_of_PS/Narela.json'
const Alipur = alipur;
const SawroopNagar = sawroopNagar;
const BhalswaDairy = bhalswaDairy;
const Bawana = bawana;
const NIA = nia;
const SamaypurBaldi = samaypurBaldi;
const ShahabadDairy = shahabadDairy;
const Narela = narela;

function loadGoogleMapsScript() {
  return new Promise((resolve) => {
    const existingScript = document.getElementById('googleMaps');
    
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAxEYPIcz4RCV2q6VC3JbMmTQahq9yVaG4&libraries=visualization`;
      script.id = 'googleMaps';
      script.async = true;
      script.defer = true;
      script.onload = () => {
        resolve();
      };
      script.onerror = () => {
        console.error("Error loading Google Maps script");
        resolve();
      };
      document.body.appendChild(script);
    } else {
      resolve();
    }
  });
}

function OuterNorthDistrictMap({ cctv = [], crimeData = [], staffData = [], pcrData = []  }) {
  const mapRef = useRef(null);
  const markersRef = useRef({ crime: [], staff: [], cctv: [],pcrData : [] });
  const heatmapRef = useRef(null); // Define heatmapRef here
  const clustererRef = useRef(null);
  const polygonsRef = useRef([]);

  const DCP_OUTER_NORTH_DELHI_HOME = {lat : 28.724844, lng : 77.141367}
  const bounds = {
    north: 28.929248, // Northernmost latitude of Delhi
    south: 28.382100, // Southernmost latitude of Delhi
    east: 77.452571,  // Easternmost longitude of Delhi
    west: 76.738435  // Westernmost longitude of Delhi
  };

  // Map crime types to icons
  const crimeIcons = {
    BURGLARY: Burglary,
    'HOUSE THEFT': HouseTheft,
    'MV THEFT': MVTHEFT,
    ROBBERY: Robbery,
    SNATCHING: SNATCHING,
  };

  const polygonConfigs = [        
    { paths: Alipur, color: '#C04000', name: 'Alipur' },
    { paths: SawroopNagar, color: '#008000', name: 'Sawroop Nagar' },
    { paths: BhalswaDairy, color: '#C11C84', name: 'Bhalswa Dairy' },
    { paths: Bawana, color: '#C11C84', name: 'Bawana' },
    { paths: NIA, color: '#0000FF', name: 'NIA' },
    { paths: SamaypurBaldi, color: '#ADFF2F', name: 'Samaypur Baldi' },
    { paths: ShahabadDairy, color: '#FFFF00', name: 'Shahabad Dairy' },
    { paths: Narela, color: '#ADFF2F', name: 'Narela' },
  ];
  const initMap = () => {
    if (window.google) {
      const mapInstance = new window.google.maps.Map(mapRef.current, {
        zoom: 12,
        center: { lat: 28.795730, lng: 77.096237 },
        mapTypeId: 'terrain',
      });
      polygonsRef.current = polygonConfigs.map(({ paths, color, name }) => {
        const polygon = new window.google.maps.Polygon({
          paths,
          strokeColor: color,
          strokeOpacity: 0.8,
          strokeWeight: 4,
          fillColor: color,
          fillOpacity: 0.35,
        });polygon.setMap(mapInstance); // Use mapInstance here

  const labelMarker = new window.google.maps.Marker({
    position: getPolygonCenter(polygon),
    map: mapInstance, // Use mapInstance here
    label: {
      text: name, // Ensure name is used correctly
      color: '#000',
      fontSize: '14px',
      fontWeight: 'bold'
    },
    icon: {
      path: window.google.maps.SymbolPath.CIRCLE,
      fillColor: 'transparent',
      fillOpacity: 0,
      strokeWeight: 0,
      scale: 0  // Marker icon is invisible
    },
    optimized: false
  });
  return polygon;
});
      mapInstance.addListener("zoom_changed", () => {
        const zoomLevel = mapInstance.getZoom();
        if (zoomLevel < 15) {
          // Show clusters with density ovals
          clustererRef.current.addMarkers(markersRef.current.crime); // Show clusters
        } else {
          // Show individual markers without clustering
          clustererRef.current.clearMarkers(); // Hide clusters
          showIndividualMarkers(); // Show individual markers
        }
      });
      mapInstance.addListener("zoom_changed", () => {
        const zoomLevel = mapInstance.getZoom();
        if (zoomLevel < 15) {
          mapInstance.setMapTypeId("terrain"); // Switch to terrain view at lower zoom levels
        } else {
          mapInstance.setMapTypeId("satellite"); // Switch to satellite view at higher zoom levels
        }
      });

      // Add a marker for DCP Outer North Delhi Office
      new window.google.maps.Marker({
        position: DCP_OUTER_NORTH_DELHI_HOME,
        map: mapInstance,
        icon: { url: house, scaledSize: new window.google.maps.Size(32, 32) },
        title: 'DCP Outer North Delhi Office',
      });

      mapRef.current.__mapInstance = mapInstance;
  
      // Initialize MarkerClusterer with custom renderer for density ovals
      clustererRef.current = new MarkerClusterer({
        map: mapInstance,
        markers: [],
        maxZoom: 15,
        renderer: {
          render: ({ count, position }) => {
            // Define color based on density
            let color;
            if (count > 12) color = 'rgba(255, 0, 0, 0.3)'; // Red for high density
            else if (count > 5) color = 'rgba(255, 255, 0, 0.3)'; // Yellow for medium density
            else color = 'rgba(0, 255, 0, 0.3)'; // Green for low density
  
            // Render an oval for each cluster
            return new window.google.maps.Marker({
              position,
              icon: {
                path: 'M -20,0 A 20,20 0 1,1 20,0 A 20,20 0 1,1 -20,0 Z',
                fillColor: color,
                fillOpacity: 0.4,
                strokeWeight: 0,
                scale: Math.log(count) * 4, // Scale the oval based on count
              },
              label: {
                text: String(count),
                color: 'black',
                fontSize: '12px',
                fontWeight: 'bold',
              },
            });
          },
        },
      });
  
      // Add crime markers to the clusterer
      addMarkersToClusterer();
      mapInstance.addListener("zoom_changed", () => {
        const zoomLevel = mapInstance.getZoom();
        if (zoomLevel < 15) {
          // Show clusters with density ovals
          clustererRef.current.addMarkers(markersRef.current.crime); // Show clusters
        } else {
          // Show individual markers without clustering
          clustererRef.current.clearMarkers(); // Hide clusters
          showIndividualMarkers(); // Show individual markers
        }
      });
  
      // Add a listener to toggle clusters vs. individual markers based on zoom
      mapInstance.addListener("zoom_changed", () => {
        const zoomLevel = mapInstance.getZoom();
        if (zoomLevel < 15) {
          // Show clusters with density ovals
          clustererRef.current.addMarkers(markersRef.current.crime); // Show clusters
        } else {
          // Show individual markers without clustering
          clustererRef.current.clearMarkers(); // Hide clusters
          showIndividualMarkers(); // Show individual markers
        }
      });
    } else {
      console.error("Google Maps API not loaded.");
    }
  };
  
  const addMarkersToClusterer = () => {
    const newMarkers = crimeData.map(crime => {
      const lat = parseFloat(crime.lat);
      const lng = parseFloat(crime.long);
      const icon = getIconForCrimeType(crime.crime);
  
      const marker = new window.google.maps.Marker({
        position: { lat, lng },
        icon: { url: icon, scaledSize: new window.google.maps.Size(32, 32) },
      });
  
      markersRef.current.crime.push(marker); // Add to crime markers array
      return marker;
    });
  
    // Add crime markers to clusterer
    clustererRef.current.addMarkers(newMarkers);
  };
  
  const showIndividualMarkers = () => {
    markersRef.current.crime.forEach(marker => {
      marker.setMap(mapRef.current.__mapInstance);
    });
  };
  
  const getIconForCrimeType = (crimeType) => {
    const crimeIcons = {
      BURGLARY: Burglary,
      'HOUSE THEFT': HouseTheft,
      'MV THEFT': MVTHEFT,
      ROBBERY: Robbery,
      SNATCHING: SNATCHING,
    };
    return crimeIcons[crimeType] || police;
  };
  
  useEffect(() => {
    loadGoogleMapsScript().then(initMap);
  }, []);
  


  
  // PCR data handling
  useEffect(() => {
    loadGoogleMapsScript().then(initMap);
  }, []);

  useEffect(() => {
    const mapInstance = mapRef.current.__mapInstance;

    if (mapInstance && Array.isArray(crimeData)) {
      // Remove existing crime markers
      markersRef.current.crime.forEach(marker => marker.setMap(null));
      markersRef.current.crime = [];

      // Create an InfoWindow instance
      const infoWindow = new window.google.maps.InfoWindow();

      // Add new markers for crime data
      crimeData.forEach(crime => {
        const lat = parseFloat(crime.lat);
        const lng = parseFloat(crime.long);
        const crimeType = crime.crime;

        if (!isNaN(lat) && !isNaN(lng)) {
          const iconUrl = crimeIcons[crimeType] || crimeIcons['ALL']; // Use a default if necessary

          const marker = new window.google.maps.Marker({
            position: { lat, lng },
            map: mapInstance,
            title: crimeType,
            icon: {
              url: iconUrl,
              scaledSize: new window.google.maps.Size(32, 32), // Adjust size as needed
            },
          });

          // Add click event listener to the marker
          marker.addListener('click', () => {
            // Set the content of the InfoWindow
            infoWindow.setContent(`Crime: ${crimeType}<br>Latitude: ${lat}<br>Longitude: ${lng}`);
            // Open the InfoWindow at the marker's position
            infoWindow.open(mapInstance, marker);
          });

          markersRef.current.crime.push(marker);
        } else {
          console.warn(`Invalid coordinates for crime: ${crime} `);
        }
      });
    } else {
      console.warn("Map instance or crime data is not available");
    }
  }, [crimeData]);

  // Staff data handling
  useEffect(() => {
    const mapInstance = mapRef.current.__mapInstance;

    if (mapInstance && Array.isArray(staffData)) {
      // Clear existing staff markers
      markersRef.current.staff.forEach(marker => marker.setMap(null));
      markersRef.current.staff = [];

      // Add new markers for each staff location
      staffData.forEach(staff => {
        const { lat, lng, name, startTime, endTime, phone } = staff;

        const marker = new window.google.maps.Marker({
          position: { lat, lng },
          map: mapInstance,
          title: `${name} (${phone})`,
          icon: {
            url: police, // Update this path to your police icon image
            scaledSize: new window.google.maps.Size(32, 32), // Adjust size as needed
          },
        });

        // Info window with staff details
        const infoWindow = new window.google.maps.InfoWindow({
          content: `
            <div>
              <h3>${name}</h3>
              <p><strong>Phone:</strong> ${phone}</p>
              <p><strong>Start Time:</strong> ${startTime}</p>
              <p><strong>End Time:</strong> ${endTime}</p>
            </div>
          `,
        });

        // Open info window on marker click
        marker.addListener('click', () => {
          infoWindow.open(mapInstance, marker);
        });

        markersRef.current.staff.push(marker);
      });
    }
  }, [staffData]);

// CCTV data handling
useEffect(() => {
  const mapInstance = mapRef.current.__mapInstance;

  if (mapInstance && Array.isArray(cctv)) {
    // Clear existing CCTV markers
    markersRef.current.cctv.forEach(marker => marker.setMap(null));
    markersRef.current.cctv = [];

    // Add new markers for each CCTV location
    cctv.forEach((cctvCamera, index) => {
      const { lat, long, cameraType, name, notes } = cctvCamera;

      let iconUrl = cctvIcon; // default CCTV icon
      if (cameraType === 'ECIL') {
        iconUrl = ECILcctvIcon;
      } else if (cameraType === 'ANRP') {
        iconUrl = ANPRcctvIcon;
      } else if (cameraType === 'private') {
        iconUrl = privateCctvIcon; // Assuming you have a privateIcon
      }

      const marker = new window.google.maps.Marker({
        position: { lat, lng: long },
        map: mapInstance,
        title: `CCTV Camera ${index + 1}`,
        icon: {
          url: iconUrl, // Update this path to your CCTV icon image
          scaledSize: new window.google.maps.Size(32, 32), // Adjust size as needed
        },
      });

      // Info window with CCTV details
      const infoWindow = new window.google.maps.InfoWindow({
        content:
          `<div>
            <h3>CCTV Camera ${index + 1}</h3>
            <p>Latitude: ${lat}</p>
            <p>Longitude: ${long}</p>
            ${cameraType === 'private' ? `<p><strong>Name:</strong> ${name}</p><p><strong>Notes:</strong> ${notes}</p>` : ''}
          </div>`,
      });

      // Open info window on marker click
      marker.addListener('click', () => {
        infoWindow.open(mapInstance, marker);
      });

      markersRef.current.cctv.push(marker);
    });
  }
}, [cctv]);

  // Crime data handling
useEffect(() => {
  const mapInstance = mapRef.current.__mapInstance;

  if (mapInstance && Array.isArray(pcrData)) {
    // Clear existing PCR markers from map
    markersRef.current.pcrData = markersRef.current.pcrData || [];
    markersRef.current.pcrData.forEach(marker => marker.setMap(null));
    markersRef.current.pcrData = [];

    const infoWindow = new window.google.maps.InfoWindow();

    // Plot markers for each PCR entry
    pcrData.forEach((pcr, index) => {
      const { latitude, longitude, Date, IPC, BNS, PS } = pcr;
      const FIR_No = pcr["FIR No."]; // Use bracket notation for 'FIR No.'
      const Place_of_occurrence = pcr["Place of occurrence"]; // Use bracket notation for 'Place of occurrence'

      // Ensure latitude and longitude are valid numbers
      const lat = parseFloat(latitude);
      const lng = parseFloat(longitude);

      if (!isNaN(lat) && !isNaN(lng)) {
        const marker = new window.google.maps.Marker({
          position: { lat, lng },
          map: mapInstance,
          title: `Crime : ${index + 1}`,
          icon: {
            url: crimeGeneralIcon,
            scaledSize: new window.google.maps.Size(32, 32),
          },
        });

        // Attach information to the info window
        marker.addListener('click', () => {
          infoWindow.setContent(`
            <div>
              <h3>Crime : ${index + 1}</h3>
              <p><strong>Date:</strong> ${Date}</p>
              <p><strong>IPC:</strong> ${IPC}</p>
              <p><strong>BNS:</strong> ${BNS}</p>
              <p><strong>Police Station:</strong> ${PS}</p>
              <p><strong>FIR No:</strong> ${FIR_No}</p> <!-- Corrected to use bracket notation -->
              <p><strong>Location:</strong> ${Place_of_occurrence}</p> <!-- Corrected to use bracket notation -->
            </div>
          `);
          infoWindow.open(mapInstance, marker);
        });

        markersRef.current.pcrData.push(marker);
      } else {
        console.warn(`Invalid coordinates for PCR call:`, pcr); // Log if coordinates are invalid
      }
    });
  }
}, [pcrData]);


function getPolygonCenter(poly) {
  const bounds = new window.google.maps.LatLngBounds();
  poly.getPath().forEach(function (point) {
      bounds.extend(point);
  });
  return bounds.getCenter();
}
  return <div ref={mapRef} style={{ width: "100vw", height: "100vh" }} />;
}

export default OuterNorthDistrictMap;