import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import OuterNorthDistrictMap from '../../maps_google/outerNorthDistrictMap';
import Header from '../../Header/header';
import Sidebar from '../../maps_google/sidebar/sidebar';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

// Importing JSON files
import crimeDataJan from './crime_data_jan.json';
// import crimeDataApril from './crime_data_april.json';
// import crimeDataAug from './crime_data_aug.json';
// import crimeDataFeb from './crime_data_feb.json';
// import crimeDataJuly from './crime_data_july.json';
// import crimeDataJune from './crime_data_june.json';
// import crimeDataMay from './crime_data_may.json';
// import crimeDataMarch from './crime_data_march.json';
// import crimeDataOct from './crime_data_oct.json';
// import crimeDataSep from './crime_data_sep.json';

const codeOptions = [
  { value: 'IPC', label: 'IPC' },
  { value: 'BNS', label: 'BNS' },
];

const styles = {
  container: {
    position: 'absolute',
    top: '20px',
    right: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#ddd',
    padding: '10px 20px',
    borderRadius: '8px',
    marginTop: "70px",
    gap: '15px',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
    zIndex: 10,
  },
  label: {
    backgroundColor: '#87CEEB',
    color: '#333',
    padding: '8px 12px',
    borderRadius: '8px',
    fontSize: '16px',
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
  selectContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  entriesBox: {
    marginTop: '10px',
    padding: '10px 15px',
    backgroundColor: '#87CEEB',
    color: '#333',
    fontWeight: 'bold',
    borderRadius: '8px',
    fontSize: '14px',
    textAlign: 'center',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
  },
  mapContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
};

// Options for Police Stations and Months
const psOptions = [
  { value: "Alipur", label: "ALIPUR" },
  { value: "Bawana", label: "BAWANA" },
  { value: "Bhalswa Dairy", label: "BHALSWA DAIRY" },
  { value: "Narela", label: "NARELA" },
  { value: "Narela Indl. Area", label: "NARELA INDUSTRIAL AREA" },
  { value: "SP Badli", label: "SAMAYPUR BADLI" },
  { value: "SB Dairy", label: "SHAHBAD DAIRY" },
  { value: "Swaroop Nagar", label: "SWAROOP NAGAR" }
];

const monthOptions = [
  { value: "January", label: "January" },
  { value: "February", label: "February" },
  { value: "March", label: "March" },
  { value: "April", label: "April" },
  { value: "May", label: "May" },
  { value: "June", label: "June" },
  { value: "July", label: "July" },
  { value: "August", label: "August" },
  { value: "September", label: "September" },
  { value: "October", label: "October" },
  { value: "November", label: "November" },
  { value: "December", label: "December" },
];

const customSelectStyles = {
  control: (provided) => ({
    ...provided,
    width: '300px',
    padding: '5px',
    fontSize: '14px',
    borderRadius: '4px',
    border: '1px solid #ccc',
  }),
  placeholder: (provided) => ({
    ...provided,
    color: 'black',
  }),
  menu: (provided) => ({
    ...provided,
    fontSize: '14px',
  }),
};

const CrimeStats = () => {
  const [selectedPS, setSelectedPS] = useState([]);
  const [selectedMonths, setSelectedMonths] = useState([]);
  const [selectedCodeType, setSelectedCodeType] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedCodes, setSelectedCodes] = useState([]);
  const [filteredPcrData, setFilteredPcrData] = useState([]);
  const [totalEntries, setTotalEntries] = useState(0);

  // Combine all JSON data into one array
  const allCrimeData = [
    ...crimeDataJan, 
    // ...crimeDataApril,
    // ...crimeDataAug,
    // ...crimeDataFeb,
    // ...crimeDataJuly,
    // ...crimeDataJune,
    // ...crimeDataMay,
    // ...crimeDataMarch,
    // ...crimeDataOct,
    // ...crimeDataSep,
  ];

  const extractCodes = (codeType) => {
    return [...new Set(allCrimeData.flatMap(entry => entry[codeType] ? entry[codeType].split('/') : []))]
      .map(code => ({ value: code.trim(), label: code.trim() }));
  };

  const ipcOptions = extractCodes('IPC');
  const bnsOptions = extractCodes('BNS');

  useEffect(() => {
    setSelectedCodes([]);
  }, [selectedCodeType]);

  // useEffect(() => {
  //   const filterData = allCrimeData.filter(entry => {
  //     const dateParts = entry.Date.split('.');
  //     const year = parseInt(dateParts[2], 10) + 2000; // Adjusting for two-digit year
  //     const entryDate = new Date(year, dateParts[1] - 1, dateParts[0]);

  //     const monthIndex = parseInt(dateParts[1]) - 1;
  //     const monthName = monthOptions[monthIndex]?.value;

  //     const policeStationMatches = !selectedPS.length || selectedPS.some(ps => ps.value === entry.PS);
  //     const monthMatches = !selectedMonths.length || selectedMonths.some(month => month.value === monthName);
  //     const codeProperty = entry[selectedCodeType?.value];
  //     const codeMatches = !selectedCodes.length || (typeof codeProperty === 'string' && codeProperty.split('/').some(code =>
  //         selectedCodes.some(selectedCode => selectedCode.value === code.trim())
  //     ));

  //     const dateMatch = !selectedDate || (selectedDate && entryDate.toISOString().slice(0, 10) === selectedDate.toISOString().slice(0, 10));
  //     const dateRangeMatch = (!startDate || !endDate) || (entryDate >= startDate && entryDate <= endDate);

  //     return policeStationMatches && monthMatches && codeMatches && (dateMatch || dateRangeMatch);
  //   });

  //   setFilteredPcrData(filterData);
  //   setTotalEntries(filterData.length);
  // }, [selectedPS, selectedMonths, selectedCodes, selectedCodeType, selectedDate, startDate, endDate]);




  useEffect(() => {
    const filterData = allCrimeData.filter(entry => {
      let entryDate;
      if (entry.Date.includes('-')) {
        // Parsing "YYYY-MM-DD" format
        entryDate = new Date(entry.Date);
      } else if (entry.Date.includes('.')) {
        // Parsing "DD.MM.YY" format
        const dateParts = entry.Date.split('.');
        const year = parseInt(dateParts[2], 10) + 2000; // Adjusting for two-digit year
        entryDate = new Date(year, dateParts[1] - 1, dateParts[0]);
      }
  
      const monthIndex = entryDate ? entryDate.getMonth() : -1;
      const monthName = monthOptions[monthIndex]?.value;
  
      const policeStationMatches = !selectedPS.length || selectedPS.some(ps => ps.value === entry.PS);
      const monthMatches = !selectedMonths.length || selectedMonths.some(month => month.value === monthName);
      const codeProperty = entry[selectedCodeType?.value];
      const codeMatches = !selectedCodes.length || (typeof codeProperty === 'string' && codeProperty.split('/').some(code =>
        selectedCodes.some(selectedCode => selectedCode.value === code.trim())
      ));
  
      const dateMatch = !selectedDate || (selectedDate && entryDate.toISOString().slice(0, 10) === selectedDate.toISOString().slice(0, 10));
      const dateRangeMatch = (!startDate || !endDate) || (entryDate >= startDate && entryDate <= endDate);
  
      return policeStationMatches && monthMatches && codeMatches && (dateMatch || dateRangeMatch);
    });
  
    setFilteredPcrData(filterData);
    setTotalEntries(filterData.length);
  }, [selectedPS, selectedMonths, selectedCodes, selectedCodeType, selectedDate, startDate, endDate]);
  

  return (
    <>
      <Header />
      <Sidebar />

      <div style={styles.container}>
        <div style={styles.label}>CRIME DATA</div>

        <div style={styles.selectContainer}>
          <Select
            options={codeOptions}
            value={selectedCodeType}
            onChange={(option) => setSelectedCodeType(option)}
            placeholder="Select Code Type (IPC/BNS)"
          />

          {selectedCodeType && (
            <Select
              options={selectedCodeType.value === 'IPC' ? ipcOptions : bnsOptions}
              isMulti
              value={selectedCodes}
              onChange={setSelectedCodes}
              placeholder={`Select ${selectedCodeType.value} Code(s)`}
            />
          )}

          <Select
            options={psOptions}
            isMulti
            value={selectedPS}
            onChange={setSelectedPS}
            placeholder="Select Police Station(s)"
          />

          <Select
            options={monthOptions}
            isMulti
            value={selectedMonths}
            onChange={setSelectedMonths}
            placeholder="Select Month(s)"
          />
        </div>

        <div style={styles.entriesBox}>
          Total Entries: <strong>{totalEntries}</strong>
        </div>
      </div>

      <div style={styles.mapContainer}>
        {/* Pass filteredPcrData as a prop to OuterNorthDistrictMap */}
        <OuterNorthDistrictMap pcrData={filteredPcrData} />
      </div>
    </>
  );
};

export default CrimeStats;
