// MapMarkers.js
import React, { useEffect, useRef } from "react";
import house from "../../maps_google/dcpHouse.png";
import alipur from "../../maps_google/json_file_of_PS/Alipur.json";
import sawroopNagar from "../../maps_google/json_file_of_PS/SawroopNagar.json";
import bhalswaDairy from "../../maps_google/json_file_of_PS/BhalswaDairy.json";
import bawana from "../../maps_google/json_file_of_PS/Bawana.json";
import nia from "../../maps_google/json_file_of_PS/nia.json";
import samaypurBaldi from "../../maps_google/json_file_of_PS/SamaypurBadli.json";
import shahabadDairy from "../../maps_google/json_file_of_PS/ShahabadDairy.json";
import narela from "../../maps_google/json_file_of_PS/Narela.json";
import picket from "../../../assets/picket.png"; // Picket icon for on-road
import police from "../../../assets/police.png"; // Police icon for off-road

const Alipur = alipur;
const SawroopNagar = sawroopNagar;
const BhalswaDairy = bhalswaDairy;
const Bawana = bawana;
const NIA = nia;
const SamaypurBaldi = samaypurBaldi;
const ShahabadDairy = shahabadDairy;
const Narela = narela;

function MapMarkers({ districtData }) {
  const mapRef = useRef(null);

  function loadGoogleMapsScript() {
    return new Promise((resolve) => {
      const existingScript = document.getElementById("googleMaps");
      if (!existingScript) {
        const script = document.createElement("script");
        script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAxEYPIcz4RCV2q6VC3JbMmTQahq9yVaG4&libraries=places`;
        script.id = "googleMaps";
        script.async = true;
        script.defer = true;
        script.onload = () => resolve();
        script.onerror = () => {
          console.error("Error loading Google Maps script");
          resolve();
        };
        document.body.appendChild(script);
      } else {
        resolve();
      }
    });
  }
  const polygonConfigs = [
    { paths: Alipur, color: "#C04000", name: "Alipur" },
    { paths: SawroopNagar, color: "#008000", name: "Sawroop Nagar" },
    { paths: BhalswaDairy, color: "#C11C84", name: "Bhalswa Dairy" },
    { paths: Bawana, color: "#C11C84", name: "Bawana" },
    { paths: NIA, color: "#0000FF", name: "NIA" },
    { paths: SamaypurBaldi, color: "#ADFF2F", name: "Samaypur Baldi" },
    { paths: ShahabadDairy, color: "#FFFF00", name: "Shahabad Dairy" },
    { paths: Narela, color: "#ADFF2F", name: "Narela" },
  ];

  const initMap = () => {
    if (window.google) {
      const map = new window.google.maps.Map(mapRef.current, {
        zoom: 12,
        center: { lat: 28.79573, lng: 77.096237 },
        mapTypeId: "terrain",
      });

      new window.google.maps.Marker({
        position: { lat: 28.724844, lng: 77.141367 },
        map,
        icon: {
          url: house,
          scaledSize: new window.google.maps.Size(32, 32),
        },
        title: "DCP Outer North Delhi Office",
      });

      // Create and display polygons for each district
      polygonConfigs.forEach((config) => {
        const districtPolygon = new window.google.maps.Polygon({
          paths: config.paths,
          strokeColor: config.color,
          strokeOpacity: 0.8,
          strokeWeight: 2,
          fillColor: config.color,
          fillOpacity: 0.35,
        });
        districtPolygon.setMap(map);

        const labelMarker = new window.google.maps.Marker({
          position: getPolygonCenter(districtPolygon),
          map: map, // Correctly refer to 'map' instead of 'mapInstance'
          label: {
            text: config.name, // Correctly refer to 'config.name' instead of 'name'
            color: "#000",
            fontSize: "14px",
            fontWeight: "bold",
          },
          icon: {
            path: window.google.maps.SymbolPath.CIRCLE,
            fillColor: "transparent",
            fillOpacity: 0,
            strokeWeight: 0,
            scale: 0, // Marker icon is invisible
          },
          optimized: false,
        });
      });

      // Add markers for each district
      addMarkers(map, districtData);
    }
  };

  const addMarkers = async (map, districtData) => {
    for (const district of districtData) {
      if (district.coordinates && district.coordinates.length > 0) {
        for (const coord of district.coordinates) {
          const isOnRoad = await checkIfOnRoad(coord);
          const marker = new window.google.maps.Marker({
            position: new window.google.maps.LatLng(coord.lat, coord.long),
            map: map,
            title: isOnRoad
              ? "Increase Picket Here"
              : "Increase Police Presence Here",
            icon: {
              url: isOnRoad ? picket : police, // Picket for on-road, police for off-road
              scaledSize: new window.google.maps.Size(32, 32),
            },
          });

          const infowindow = new window.google.maps.InfoWindow({
            content: isOnRoad
              ? "Should add a Picket here"
              : "Increase Police Presence Here",
          });

          marker.addListener("click", () => {
            infowindow.open(map, marker);
          });
        }
      } else {
        console.warn("No coordinates found for district:", district);
      }
    }
  };

  const checkIfOnRoad = async (coord) => {
    const { lat, long } = coord;
    const url = `https://roads.googleapis.com/v1/nearestRoads?points=${lat},${long}&key=AIzaSyAxEYPIcz4RCV2q6VC3JbMmTQahq9yVaG4`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      return data.snappedPoints && data.snappedPoints.length > 0; // true if the point is on a road
    } catch (error) {
      console.error("Error checking if on road:", error);
      return false; // assume not on road if there's an error
    }
  };

  useEffect(() => {
    loadGoogleMapsScript().then(initMap);
  }, [districtData]); // Reinitialize the map when data changes

  function getPolygonCenter(poly) {
    const bounds = new window.google.maps.LatLngBounds();
    poly.getPath().forEach(function (point) {
      bounds.extend(point);
    });
    return bounds.getCenter();
  }
  return <div ref={mapRef} style={{ width: "100vw", height: "100vh" }} />;
}

export default MapMarkers;
